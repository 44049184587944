import { DeleteItemById, updateArrById } from '../../../../helper/fnAllways'
import { toastSuccess } from '../../../../helper/toastHelper'
import {DISPATCH_ITEM_SELECT_MEAL_AGE,DISPATCH_DATE_TIME,DISPATCH_INPUT_NUMBER_EAT,
OPEN_TONG_LUONG_DUONG_CHAT,CLOSE_TONG_LUONG_DUONG_CHAT,DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE,
DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE,DISPATCH_UPDATE_DATE_MEAL_NUTRIKID,DELETE_DATE_MEAL_NUTRIKID,OPEN_TABLE_MEAL_NUTRIKID,CLOSE_TABLE_MEAL_NUTRIKID,
DISPATCH_INPUT_MONEY_EAT,DISPATCH_ITEM_ADD_MATERIAL_NUTRIKID, ADD_MATERIAL_DATE_NUTRIKID,
DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE,
UPDATE_MASS_IN_MATERIAL_DATE_NUTRIKID,
UPDATE_PRICE_IN_MATERIAL_DATE_NUTRIKID,
UPDATE_PRICE_LOSE_IN_MATERIAL_DATE_NUTRIKID,
UPDATE_REPO_MATERIAL_DATE_NUTRIKID,DELETE_REPO_MATERIAL_DATE_NUTRIKID,
DISPATCH_KET_QUA_DUONG_CHAT,
//in hoa don
OPEN_RESULT_MEAL_NUTRIKID,CLOSE_RESULT_MEAL_NUTRIKID,VALUE_SELECT_RESULT_MEAL_NUTRIKID,
OPEN_ADD_ITEM_MEAL_NUTRIKID,CLOSE_ADD_ITEM_MEAL_NUTRIKID,
OPEN_UPDATE_ITEM_MEAL_NUTRIKID,CLOSE_UPDATE_ITEM_MEAL_NUTRIKID,OPEN_PRINT_MEAL_NUTRIKID,CLOSE_PRINT_MEAL_NUTRIKID,
OPEN_TONG_HOP_CHO_NGAY,CLOSE_TONG_HOP_CHO_NGAY,
OPEN_ADD_LUY_KE,CLOSE_ADD_LUY_KE,DISPATCH_LUY_KE_TO_STORE,OPEN_UPDATE_LUY_KE,CLOSE_UPDATE_LUY_KE,
UPDATE_LUY_KE,DISPATCH_LUY_KE_DAU_KY, UPDATE_NUMBER_EAT_MEAL_NUTRIKID,UPDATE_MEAL_EAT_MEAL_NUTRIKID,
OPEN_TONG_HOP_DUONG_CHAT,CLOSE_TONG_HOP_DUONG_CHAT,DISPATCH_SELECT_TONG_HOP_DUONG_CHAT, CLOSE_SELECT_TONG_HOP_DUONG_CHAT,
OPEN_SO_GIAO_NHAN_THUC_PHAM,CLOSE_SO_GIAO_NHAN_THUC_PHAM,
OPEN_PHAN_BO_NGUYEN_LIEU_NCC,CLOSE_PHAN_BO_NGUYEN_LIEU_NCC,DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE,
UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM,UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TINH_AN,
//DONG_BO_THUC_DON_TUAN_MEAL

} from '../contant/nutrikid'
import { FilterByDateTime,FindDateTimeLuyKe,HandleCheckMenuMealNutrikid, 
} from './fn'
import { UPDATE_VALUE_MEAL_WEEK } from "../contant/LibraryMeal"

import moment from 'moment'

export const  initialState={
        DateTimeLuyKe:null,
        DateTime:"",
        PrvDateTime:null,//ngay hom truoc
        ItemSelectMealAge:"",
        NumberEat:0,
        MoneyEat:0,
        OpenTongLuongDuongChat:false,
        ItemMeal:"",
        ItemTableMeal:"",
        OpenTableMeal:false,
        ListMeal:[],
        ListMealOrigin:[],
        ItemAddMaterial:null,
        KetQuaDuongChat:null,
        //inhoa don
        OpenResultMealNutrikid:false,
        ValueResultMealNutrikid:null,
        OpenAddMealNutrikid:false,
        ValueAddMealNutrikid:null,
        ItemMenu:null,
        OpenUpdateMealNutrikid:false,
        OpenPrintMealNutrikid:false,
        OpenTongHopChoNgay:false,
        OpenSoGiaoNhanThucPham:false,
        OpenPhanBoNguyenLieuNCC:false,
        ValueTongHopChoNgay:false,
        OpenAddLuyKe:false,   
        OpenUpdateLuyKe:false,
        OpenTongHopDuongChat:false,
        OpenSelectTongDuognChat:false,
        ValueSelectTongDuongChat:null,
        //ValueLuyKe:null
        ItemDongBoMeal:null,
        ItemPhanBoNguyenLieuThucPham:null,
        ItemPhanBoNguyenLieuThucPhamTinhAn:null

}


const Index =(state=initialState,action)=>{
    switch (action.type) {
       
        case UPDATE_VALUE_MEAL_WEEK:
            // console.log(action.payload.item)
            // console.log(state.ListMealOrigin)
            // console.log(state.ListMeal)
            return{
                ...state,
                ListMeal:updateArrById(state.ListMeal,action.payload.item),
               // ListMealOrigin:updateArrById(state.ListMealOrigin,action.payload.item)            
            }
        case UPDATE_MEAL_EAT_MEAL_NUTRIKID:
            return{
                ...state,
                ListMeal:updateArrById(state.ListMeal,action.payload.item),
                ListMealOrigin:updateArrById(state.ListMealOrigin,action.payload.item)
            }
        case UPDATE_NUMBER_EAT_MEAL_NUTRIKID:
            return{
                ...state,
                ListMeal:updateArrById(state.ListMeal,action.payload.item),
                ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
                ItemTableMeal:action.payload.item
            }

        case DISPATCH_LUY_KE_DAU_KY:
            //let {arrListMeal,newItemMeal} =action.payload.item
            //let handleUpdate = UpdateListMeal(state.ListMeal,arrListMeal)
            return{
                ...state,
              //  ItemTableMeal:newItemMeal,
               // ListMeal:handleUpdate,
                //ListMealOrigin:UpdateListMeal(state.ListMealOrigin,arrListMeal)
            }
        case UPDATE_LUY_KE:
            let Update = updateArrById(state.ListMealOrigin,action.payload.item)
            //let findItemTableMeal =(Update.filter(v=>v!==10)).filter(v=>v._id===state.ItemTableMeal._id)
            //console.log(findItemTableMeal)
            return{
                ...state,
                DateTimeLuyKe:action.payload.item,
                ListMealOrigin:Update,
                ListMeal:Update.filter(v=>v!==10),
                //ItemTableMeal:findItemTableMeal               
            }
        case OPEN_UPDATE_LUY_KE:
            return{
                ...state,
                OpenUpdateLuyKe:true
            }
       case CLOSE_UPDATE_LUY_KE:
            return{
                ...state,
                OpenUpdateLuyKe:false
            }
        case DISPATCH_LUY_KE_TO_STORE:
            //let findMienLuyKe = FindDateTimeLuyKe((state.ListMeal.concat(action.payload.item)),state.DateTime)
            return{
                ...state,
                ListMealOrigin:state.ListMealOrigin.concat(action.payload.item),
                ListMeal:(state.ListMeal.concat(action.payload.item)).filter(v=>v.typeOf!==10),
                DateTimeLuyKe:FindDateTimeLuyKe((state.ListMealOrigin.concat(action.payload.item)),state.DateTime,state.ItemTableMeal)
            }
        case DISPATCH_KET_QUA_DUONG_CHAT:          
            return{
                ...state,
                KetQuaDuongChat:action.payload.item
            }
        case DELETE_REPO_MATERIAL_DATE_NUTRIKID:   
            return{
                ...state,
               ListMeal:updateArrById(state.ListMeal,action.payload.item),
               ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
               ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
               ItemTableMeal:action.payload.item
            }
        case UPDATE_PRICE_IN_MATERIAL_DATE_NUTRIKID:            
            return{
                ...state,
               ListMeal:updateArrById(state.ListMeal,action.payload.item),
               ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
               ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
               ItemTableMeal:action.payload.item
            }
        case UPDATE_PRICE_LOSE_IN_MATERIAL_DATE_NUTRIKID:            
            return{
                ...state,
               ListMeal:updateArrById(state.ListMeal,action.payload.item),
               ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
               ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
               ItemTableMeal:action.payload.item
            }
        case UPDATE_MASS_IN_MATERIAL_DATE_NUTRIKID:            
            return{
                ...state,
               ListMeal:updateArrById(state.ListMeal,action.payload.item),
               ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
               ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
               ItemTableMeal:action.payload.item
            }
           case UPDATE_REPO_MATERIAL_DATE_NUTRIKID:            
            return{
                ...state,
               ListMeal:updateArrById(state.ListMeal,action.payload.item),
               ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
               ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
               ItemTableMeal:action.payload.item
            }
        case DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE:
            //let filterListMeal_ = 
            return{
                ...state,
                ListMeal:updateArrById(state.ListMeal,action.payload.item),
                ItemMeal:FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime),
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(updateArrById(state.ListMeal,action.payload.item),state.DateTime)),
                ItemTableMeal:action.payload.item
            }
        case DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE:
            let FilterListMealAdd = (state.ListMeal.concat(action.payload.item)).filter(v=>v.typeOf!==10)
            return{
                ...state,                    
                ListMeal:FilterListMealAdd,
                ItemMeal:FilterByDateTime(FilterListMealAdd,state.DateTime),
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(FilterListMealAdd,state.DateTime)),
                ItemSelectMealAge:"",
                NumberEat:0,
                MoneyEat:0
            }
        case DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE: 
            let  filterListMeal = action.payload.item.filter(v=>v.typeOf!==10)
                return{
                ...state,
                ListMealOrigin:action.payload.item,
                ListMeal:filterListMeal,
                ItemMeal:FilterByDateTime(filterListMeal,state.DateTime),                
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(filterListMeal,state.DateTime)),
                DateTimeLuyKe:FindDateTimeLuyKe(action.payload.item,state.DateTime,state.ItemTableMeal)     
            }
        case DISPATCH_DATE_TIME:
            return{
                ...state,
                PrvDateTime:moment(action.payload.item).add(-1, 'days'),
                DateTime:action.payload.item,
                ItemMeal:FilterByDateTime(state.ListMeal,action.payload.item),
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(state.ListMeal,action.payload.item)),
                DateTimeLuyKe:FindDateTimeLuyKe(state.ListMealOrigin,action.payload.item,state.ItemTableMeal)                
            }
        case DISPATCH_UPDATE_DATE_MEAL_NUTRIKID:
            toastSuccess('cập nhật thành công') 
            let FilterListMeal = (updateArrById(state.ListMeal,action.payload.item)).filter(v=>v.typeOf!==10)
            return{
                ...state,
                ListMeal:FilterListMeal,
                ItemMeal:FilterByDateTime(FilterListMeal,state.DateTime),
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(FilterListMeal,state.DateTime)),
                ItemTableMeal:action.payload.item
            }
        case DELETE_DATE_MEAL_NUTRIKID:
            toastSuccess('xóa thành công') 
            let FilterListMeal_ =(DeleteItemById(state.ListMeal,action.payload.item)).filter(v=>v.typeOf!==10)
            return{
                ...state,
                ListMeal:FilterListMeal_,
                ItemMeal:FilterByDateTime(FilterListMeal_,state.DateTime),
                ItemMenu:HandleCheckMenuMealNutrikid(FilterByDateTime(FilterListMeal_,state.DateTime))
            }
        case OPEN_TABLE_MEAL_NUTRIKID:
            return{
                ...state,
                OpenTableMeal:true,
                ItemTableMeal:action.payload.item,
                DateTimeLuyKe:FindDateTimeLuyKe(state.ListMealOrigin,state.DateTime,action.payload.item)                
            }
        case CLOSE_TABLE_MEAL_NUTRIKID:
            return{
                ...state,
                OpenTableMeal:false,
                ItemTableMeal:"",
                KetQuaDuongChat:null
            }
        case DISPATCH_ITEM_SELECT_MEAL_AGE:
            return{
                ...state,
                ItemSelectMealAge:action.payload.item
            }
        case DISPATCH_INPUT_NUMBER_EAT:
            return{
                ...state,
                NumberEat:+(action.payload.item)
            }
        case DISPATCH_INPUT_MONEY_EAT:
            return{
                ...state,
                MoneyEat:+(action.payload.item)
            }
      
        case OPEN_TONG_LUONG_DUONG_CHAT:
            return{
                ...state,
                OpenTongLuongDuongChat:true
            }
        case CLOSE_TONG_LUONG_DUONG_CHAT:
            return{
                ...state,
                OpenTongLuongDuongChat:false
            }               
        case DISPATCH_ITEM_ADD_MATERIAL_NUTRIKID:
            return{
                ...state,
                ItemAddMaterial:action.payload.item
            }
        case ADD_MATERIAL_DATE_NUTRIKID:
            return{
                ...state,
                ItemAddMaterial:null
            }
        //in hoa don
        case OPEN_RESULT_MEAL_NUTRIKID:
            return{
                ...state,
                OpenResultMealNutrikid:true
            }
        case CLOSE_RESULT_MEAL_NUTRIKID:
            return{
                ...state,
                OpenResultMealNutrikid:false,
                ValueResultMealNutrikid:null
            }
        case VALUE_SELECT_RESULT_MEAL_NUTRIKID:
            return{
                ...state,
                ValueResultMealNutrikid:action.payload.item
            }
        case OPEN_ADD_ITEM_MEAL_NUTRIKID:
            return{
                ...state,
                OpenAddMealNutrikid:true,
                //ValueAddMealNutrikid:action.payload.item
            }
        case CLOSE_ADD_ITEM_MEAL_NUTRIKID:
            return{
                ...state,
                OpenAddMealNutrikid:false,
                //ValueAddMealNutrikid:""
            }
        case OPEN_UPDATE_ITEM_MEAL_NUTRIKID:
            return{
                ...state,
                OpenUpdateMealNutrikid:true
            }
        case CLOSE_UPDATE_ITEM_MEAL_NUTRIKID:
            return{
                ...state,
                OpenUpdateMealNutrikid:false
            }
        case OPEN_PRINT_MEAL_NUTRIKID:
            return{
                ...state,
                OpenPrintMealNutrikid:true
            }
        case CLOSE_PRINT_MEAL_NUTRIKID:
            return{
                ...state,
                OpenPrintMealNutrikid:false
            }
     
       case OPEN_TONG_HOP_CHO_NGAY:
           return{
                ...state,
                OpenTongHopChoNgay:true,
                ValueTongHopChoNgay:true
           }
        case CLOSE_TONG_HOP_CHO_NGAY:
            return{
                 ...state,
                 OpenTongHopChoNgay:false,
                 ValueTongHopChoNgay:false
            }
        case OPEN_ADD_LUY_KE:
            return{
                ...state,
                OpenAddLuyKe:true
            }
        case CLOSE_ADD_LUY_KE:
            return{
                ...state,
                OpenAddLuyKe:false
            }
        case OPEN_TONG_HOP_DUONG_CHAT:
            return{
                ...state,
                OpenTongHopDuongChat:true
            }
       case CLOSE_TONG_HOP_DUONG_CHAT:
            return{
                ...state,
                OpenTongHopDuongChat:false
            }
        case DISPATCH_SELECT_TONG_HOP_DUONG_CHAT:
            return{
                ...state,
                OpenSelectTongDuongChat:true,
                ValueSelectTongDuongChat:action.payload.item
            }
        case CLOSE_SELECT_TONG_HOP_DUONG_CHAT:
            return{
                ...state,
                OpenSelectTongDuongChat:false,
                ValueSelectTongDuongChat:null
            }
        case OPEN_SO_GIAO_NHAN_THUC_PHAM:
            return{
                ...state,
                OpenSoGiaoNhanThucPham:true
            }
            case CLOSE_SO_GIAO_NHAN_THUC_PHAM:
                return{
                    ...state,
                    OpenSoGiaoNhanThucPham:false
                }
            case OPEN_PHAN_BO_NGUYEN_LIEU_NCC:
                return{
                    ...state,
                    OpenPhanBoNguyenLieuNCC:true
                }
            case CLOSE_PHAN_BO_NGUYEN_LIEU_NCC:
                return{
                    ...state,
                    OpenPhanBoNguyenLieuNCC:false
                }
        // case DONG_BO_THUC_DON_TUAN_MEAL:
        //     toastSuccess('Cập nhật thành công')
        //     return{
        //         ...state,
        //         ItemDongBoMeal:action.payload.item
        //     }
                case DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE:
                    return{
                        ...state,
                        ItemPhanBoNguyenLieuThucPham:action.payload.item.getNutrikid,
                        ItemPhanBoNguyenLieuThucPhamTinhAn:action.payload.item.getTinhAn
                    }
                case UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM:
                    toastSuccess('Cập Nhật Thành Công')
                    return{
                        ...state,
                        ItemPhanBoNguyenLieuThucPham:action.payload.item
                    }
                case UPDATE_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TINH_AN:
                    toastSuccess('Cập Nhật Thành Công')
                    return{
                        ...state,
                        ItemPhanBoNguyenLieuThucPhamTinhAn:action.payload.item
                    }
         
        default: return state
    }
}

export default Index