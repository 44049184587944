import {DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE ,DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE,
DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE,DISPATCH_LUY_KE_TO_STORE,DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE
} from "../contant/nutrikid";

export const DispatchAddMealNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_ADD_MEAL_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchGetMealNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_GET_DATE_MEAL_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchAddMaterialDateNutrikidToStore=(item)=>{
    return{
        type:DISPATCH_ADD_MATERIAL_DATE_NUTRIKID_TO_STORE,
        payload:{item}
    }
}

export const DispatchLuyKeToStore=(item)=>{
    return{
        type:DISPATCH_LUY_KE_TO_STORE,
        payload:{item}
    }
}

export const DispatchItemPhanBoNguyenLieuThucPhamToStore=(item)=>{
    return{
        type:DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_THUC_PHAM_TO_STORE,
        payload:{item}
    }
}