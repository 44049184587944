import axiosServices from '../../../../api-services/api'
import {API_ENDPOINT} from  '../../../../contant/index'


const urlAddMealNutrikid               = "addMealNutrikid"
const urlGetMealDateNutrikid           = "getMealDateNutrikid"
const urlUpdateMealDateNutrikid        ="updateMealDateNutrikid"
const urlDeleteMealDateNutrikid        = "deleteMealDateNutrikid"
const urlAddMaterialDateNutrikid       = "AddMaterialDateNutrikid"
const urlAddLuyKeNutrikid              = 'AddLuyKeNutrikid'
const urlUpdateLuyKeNutrikid           = 'UpdateLuyKeNutrikid'
const urlGetPhanBoNguyenLieuThucPham  = 'GetPhanBoNguyenLieuThucPham'
const urlUpdateItemPhanBoNguyenLieuThucPham= 'UpdateItemPhanBoNguyenLieuThucPham'
const urlUpdateItemPhanBoNguyenLieuThucPhamTinhAn= 'UpdateItemPhanBoNguyenLieuThucPhamTinhAn'

export const AddMealNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddMealNutrikid}`,item)
}

export const GetMealDateNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetMealDateNutrikid}`,item)
}

export const UpdateMealDateNutrikid=(item)=>{
        return axiosServices.post(`${API_ENDPOINT}/${urlUpdateMealDateNutrikid}`,item)
}

export const DeleteMealDateNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlDeleteMealDateNutrikid}`,item)
}

export const AddMaterialDateNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddMaterialDateNutrikid}`,item)
}

export const AddLuyKeNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlAddLuyKeNutrikid}`,item)
}

export const UpdateLuyKeNutrikidApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateLuyKeNutrikid}`,item)
}

export const GetPhanBoNguyenLieuThucPhamApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlGetPhanBoNguyenLieuThucPham}`,item)
}

export const UpdateItemPhanBoNguyenLieuThucPhamApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemPhanBoNguyenLieuThucPham}`,item)
}


export const UpdateItemPhanBoNguyenLieuThucPhamTinhAnApi=(item)=>{
    return axiosServices.post(`${API_ENDPOINT}/${urlUpdateItemPhanBoNguyenLieuThucPhamTinhAn}`,item)
}